import * as React from "react";
import { HeadFC, Link, PageProps, graphql } from "gatsby";
import { Link as MuiLink, Typography, TypographyProps, styled } from '@mui/material';
import Layout from "../components/layout";
import Seo from "../components/seo";

type DataProps = {
  site: {
    siteMetadata: {
      facebook: string,
      instagram: string,
      tiktok: string,
    }
  }
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        facebook
        instagram
        tiktok
      }
    }
  }
`
const TypographyCentered = styled(Typography)<TypographyProps>(({theme}) => ({
  maxWidth: '100%',
  //textAlign: 'center',
}))

const ContactUsPage: React.FC<PageProps<DataProps>> = ({data, location}) => 
{
  console.log("data from query "+ data);
  return (
  <Layout>
      <TypographyCentered variant="h1">
      Psst... Here's How to Whisper to Ultra Handy
      </TypographyCentered>

      <TypographyCentered variant="h2">
      Got Questions, Praises, or Just Want to Say Hi?
      </TypographyCentered>

      <TypographyCentered>
      Hey you! Yes, you, staring at the screen wondering 
      if we're real people or just super intelligent robots. 
      Spoiler alert: we're as real as the coffee stain on your shirt (don't look now, just trust us).
      Welcome to our Contact Us page, where we promise to be as helpful as a pocket on a shirt and twice as entertaining.
      </TypographyCentered>

      <TypographyCentered variant="h2">
      Need Help? We've Got Your Back!
      </TypographyCentered>

      <TypographyCentered>
      Got a question? 
      Lost in the wilderness of our website? 
      Just want to chat about the meaning of life or why pineapples on pizza is a controversial topic? 
      Shoot us a message! We're here to help, listen, and occasionally provide 
      unsolicited advice on outdoor survival skills 
      (like how to use your Ultra Handy bit keychain holder to open a stubborn jar, it's not possible I can tell you).
      </TypographyCentered>

      <TypographyCentered variant="h2">
      Feedback: The Good, The Bad, and The "Please Don't Yell"
      </TypographyCentered>

      <TypographyCentered>
      Love our stuff? Tell us, and we'll do a happy dance. Something's not right? 
      Tell us, and we'll do a sad shuffle, then fix it ASAP.
      We're all about making your experience as smooth as a kayak gliding over a Finnish lake at dawn. 
      So, let us know what's up - the good, the bad, and the "I can't believe I need to tell you this."
      </TypographyCentered>

      <TypographyCentered variant="h2">
      Collaborations: Let's Make Magic Together
      </TypographyCentered>

      <TypographyCentered>
      Got a wild idea for a collaboration? Think your brand and ours could create the next big thing in the world of gear and giggles? We're all ears! Drop us a line, and let's brainstorm over virtual coffee (or real coffee if you're in the neighborhood). We love making new friends and creating stuff that makes the world a tad more interesting.
      </TypographyCentered>

      <TypographyCentered variant="h2">
      Contact Details: The Nitty-Gritty
      </TypographyCentered>

      <Typography>
      Alright, down to business. Here's how you can reach us:<br/>
      <b>Email:</b>support@forestjoker.com<br/>
      <b>Phone:</b>+1 (307) 285-9200<br/>
      <b>Social</b><br/>
      <ul>
        <li><MuiLink href={data.site.siteMetadata.facebook} rel="noopener" target="_blank">Facebook</MuiLink></li>
        <li><MuiLink href={data.site.siteMetadata.instagram} rel="noopener" target="_blank">Instagram</MuiLink></li>
        <li><MuiLink href={data.site.siteMetadata.tiktok} rel="noopener" target="_blank">TikTok</MuiLink></li>
      </ul>
      <br/>
      <b>We're Here, We love humour, hope you don't mind</b>
      <br/>
      At Ultra Handy, we're all about keeping things fun, functional, and a little funky. 
      Whether you've got a question, a story, or just want to share a laugh, we're here for it. 
      So, don't be a stranger - drop us a line, and let's make the magic happen!
    
      </Typography>
      <TypographyCentered>
      P.S. If you're contacting us to ask if we're available for a space mission, the answer is a resounding "heck yes!" 
      (But also, maybe check with NASA first).
      <br/><br/>
      Remember to checkout our blogs (see links in footer).
      </TypographyCentered>

  </Layout>
)}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head: HeadFC<DataProps> = () => <Seo title="Home of Ultra Handy" />

export default ContactUsPage
